export const Home = () => {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img
          src={process.env.PUBLIC_URL + "/clouds.png"}
          className="App-logo"
          alt="logo"
        /> */}
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Hello World!! It's ya girl Cami!!! Listen!!! Dont click ok!! I warned
          you~~
        </a>
      </header>
      <img
        alt="kylo"
        src="/landingpage.png"
        style={{ objectFit: "contain", width: "100%" }}
      />
    </div>
  );
};
